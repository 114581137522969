<template>
  <div class="chart-wrap">
    <div class="list-empty" v-show="!proList.length">
      <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
      <p>{{ $t("member.Nodata") }}</p>
      <div class="per-empty"></div>
    </div>
    <div v-show="proList.length" class="per-chart"></div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import * as echarts from "echarts";
export default {
  data() {
    return {
      yearData: [],
      proList: [{}],
    };
  },
  mounted() {
    this.id = this.utils.decryption(this.$route.query.id);
    this.getData();
  },
  methods: {
    ...mapActions({
      getProjectExp: "moment/getProjectExp",
    }),
    async getData() {
      let res = await this.getProjectExp(this.id);
      if (res.data.length) {
        this.proList = [];
        let upList = JSON.parse(JSON.stringify(res.data));
        upList.forEach((e) => {
          let text = e.year + this.$t("member.Years");
          this.yearData.push(text);
          this.proList.push({
            name: e.year,
            value: e.projectCount,
            list: e.projectDetail,
          });
        });
        this.drawChart();
      } else {
        this.proList = [];
      }
    },
    drawChart() {
      let myChart = echarts.init(this.$el.querySelector(".per-chart"));
      let option;
      let color1 = new echarts.graphic.LinearGradient(0, 1, 0, 0, [
        {
          offset: 0.2213,
          color: "#61BFB4",
        },
        {
          offset: 0.8434,
          color: "#88C8A8",
        },
      ]);
      let color2 = new echarts.graphic.LinearGradient(0, 1, 0, 0, [
        {
          offset: 0.14,
          color: "#F0C86D",
        },
        {
          offset: 0.9988,
          color: "#FFCE5F",
        },
      ]);

      option = {
        grid: {
          top: 30,
          bottom: 10,
          left: 0,
          right: 0,
          containLabel: true,
        },
        // legend: {
        //   zlevel: 5,
        //   bottom: 0,
        //   itemWidth: 10,
        //   itemHeight: 10,
        //   itemStyle: {
        //     borderCap: "round",
        //     borderRadius: 10,
        //   },
        // },
        tooltip: {
          trigger: "item",
          //triggerOn: "click",
          axisPointer: {
            type: "none",
          },
          alwaysShowContent: true,
          position: [120, 0],
          enterable: true,
          formatter: (value) => {
            console.log(value);
            let data = value.data;
            let dom = `<h5>${this.$t(
              "moment.Majorproject"
            )}</h5><p style="font-size: 12px;color: #724707;"><span style="display:inline-block; text-align: center; background: #FFD36C;
            border-radius: 4px; width: 38px;height: 18px;line-height: 18px;">${
              data.name
            }</span> ${data.value} ${this.$t("moment.projects")}</p>`;
            data.list.forEach((e) => {
              let str = `<p style="font-size: 12px; color: rgba(0, 0, 0, 0.45);"><span style="display: inline-block; width: 8px;
height: 8px; border-radius: 4px; background: #FFFFFF;
border: 2px solid #FFD36C;"></span> ${e.projectBeginTime} - ${e.projectEndTime}</p><p style="margin-left:12px;margin-bottom:6px; font-size: 14px; color: #0E0E0E;">${e.projectName}</p>`;
              dom += str;
            });
            return dom;
          },
          extraCssText:
            "max-width:260px; height:210px; overflow-y: auto; white-space:pre-wrap",
        },
        xAxis: {
          type: "category",
          data: this.yearData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 10,
          },
        },
        yAxis: {
          type: "value",
          //   min: 0,
          //   max: 5,
          minInterval: 1,
          axisLabel: {
            color: "rgba(0, 0, 0, 0.3)",
            textStyle: {
              fontSize: 12,
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(0, 0, 0, 0.2)",
              width: 1,
              type: "dashed",
            },
          },
        },
        series: [
          {
            //name: this.$t("member.naturalyear"),
            data: this.proList,
            type: "bar",
            barMaxWidth: 80,
            barMinWidth: 40,
            label: {
              show: true,
              position: "top",
              fontSize: 18,
            },
            z: 4,
            itemStyle: {
              borderWidth: 10,
              color: color1,
            },
            emphasis: {
              itemStyle: {
                color: color2,
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
      $(window).resize(function () {
        console.log(111);
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss">
.chart-wrap {
  width: 100%;
  height: 100%;
  .per-chart {
    width: 100%;
    height: 100%;
  }
}
</style>
