<template>
  <div class="talent-wrap">
    <edp-header
      class="bf"
      :headerLeftWord="$t('moment.TalentGrowth')"
    ></edp-header>
    <div class="edp-common-content talent-content">
      <div class="info-block fac">
        <img :src="detail.photo || nullImg" alt="" class="info-pic" />
        <div class="info-text">
          <div class="text-one">
            <span class="name-text">
              {{ utils.formatLang(lang, detail.cname, detail.ename) }}</span
            >
            <em class="lable"
              ><span>{{ detail.empGenerations }}</span></em
            >
          </div>
          <div class="text-two f14">
            <em>{{ $t("milepostReport.infoTitle4") }}</em>
            <span>{{
              utils.formatLang(lang, detail.dept, detail.deptEname)
            }}</span>
            <em>{{ $t("member.Sub-department") }}</em>
            <span>{{ detail.subdivision }}</span>
            <em>{{ $t("member.Leader") }}</em>
            <span>{{
              utils.formatLang(
                lang,
                detail.directLeaderChineseName,
                detail.directLeaderEnName
              )
            }}</span>
            <em>{{ $t("member.Job-position") }}</em>
            <span>{{
              utils.formatLang(lang, detail.jobPositionCn, detail.jobPosition)
            }}</span>
          </div>
        </div>
      </div>
      <div class="chart-block fjsba">
        <div class="chart-item bf">
          <p class="fb f16 mb4">{{ $t("moment.PerformanceG") }}</p>
          <div class="chart-content">
            <performanceChart></performanceChart>
          </div>
        </div>
        <div class="chart-item bf">
          <p class="fb f16 mb4">{{ $t("moment.SalaryYoYG") }}</p>
          <div class="chart-content">
            <salaryChart></salaryChart>
          </div>
        </div>
        <div class="chart-item bf">
          <p class="fb f16 mb4">{{ $t("moment.ProjectG") }}</p>
          <div class="chart-content">
            <projectChart></projectChart>
          </div>
        </div>
        <div class="chart-item bf">
          <p class="fb f16 mb4">{{ $t("moment.PositionG") }}</p>
          <div class="chart-content">
            <positionChart></positionChart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import performanceChart from "../components/talent/performanceChart.vue";
import salaryChart from "../components/talent/salaryChart.vue";
import projectChart from "../components/talent/projectChart.vue";
import positionChart from "../components/talent/positionChart.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {
    performanceChart,
    salaryChart,
    projectChart,
    positionChart,
  },
  data() {
    return {
      detail: {},
      nullImg: require("@/src/assets/common/default.png"),
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.login.lang,
    }),
  },
  mounted() {
    this.id = this.utils.decryption(this.$route.query.id);
    this.getDetail();
  },
  methods: {
    ...mapActions({
      talentDetail: "moment/getTalentDetail",
    }),
    async getDetail() {
      let res = await this.talentDetail(this.id);
      this.detail = res.data;
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.talent-wrap {
  .talent-content {
    padding: toPad(20) toPad(40);

    .info-block {
      width: 100%;
      padding: toPad(12);
      background: #fff;
      box-shadow: 0px 4px 13px rgba(230, 236, 244, 0.6);
      border-radius: 10px;

      .info-pic {
        width: toPad(80);
        height: toPad(80);
        border-radius: toPad(40);
        margin-right: toPad(20);
        overflow-y: auto;
      }

      .info-text {
        //width: toPad(280);
        .text-one {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: toPad(18);
          line-height: toPad(24);
          margin-bottom: toPad(8);
          .name-text {
            display: inline-block;
            max-width: 80%;
          }
          .lable {
            display: inline-flex;
            color: #fff;
            border-radius: toPad(5) 0px;
            background: linear-gradient(
              90.61deg,
              #60cfc6 3.65%,
              #61bfb4 99.54%
            );
            padding: 0 toPad(4);
            text-align: center;
            margin-left: toPad(6);
            font-style: normal;
            border: 1px solid rgba(152, 244, 235, 0.6);
            line-height: toPad(16);

            span {
              transform: scale(0.9);
              font-size: toPad(12);
            }
          }
        }

        .text-two {
          //margin-bottom: toPad(12);
          em {
            font-style: normal;
            color: rgba(0, 0, 0, 0.5);
          }
          span {
            margin: 0 toPad(30) 0 toPad(10);
          }
        }
      }
    }
    .chart-block {
      width: 100%;
      flex-wrap: wrap;
      .chart-item {
        width: 49.2%;
        padding: toPad(16) toPad(20);
        margin-top: toPad(16);
        border-radius: toPad(10);
        overflow: hidden;
        .chart-content {
          width: 100%;
          height: toPad(280);
          overflow: hidden;
        }
      }
    }
  }
}
</style>
