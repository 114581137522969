<template>
  <div class="chart-wrap">
    <div class="list-empty" v-show="!perList.length">
      <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
      <p>{{ $t("member.Nodata") }}</p>
      <div class="per-empty"></div>
    </div>
    <div v-show="perList.length" class="per-chart"></div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import * as echarts from "echarts";
export default {
  data() {
    return {
      yearData: [],
      perList: [{}],
      fiscalList: [],
      currentList: [],
    };
  },
  mounted() {
    this.id = this.utils.decryption(this.$route.query.id);
    this.getPer();
  },
  methods: {
    ...mapActions({
      achievements: "moment/getAchievements",
    }),
    async getPer() {
      let res = await this.achievements(this.id);
      console.log(res);
      if (res.data.fiscalYear.length || res.data.naturalYear.length) {
        this.perList = [];
        this.perList = [...res.data.fiscalYear, ...res.data.naturalYear];

        res.data.fiscalYear.forEach((e) => {
          this.fiscalList.push({
            name: e.currentYear,
            value: e.performanceScore,
            fiscalYear: e.fiscalYear,
          });
        });
        res.data.naturalYear.forEach((e) => {
          let text = e.currentYear + this.$t("member.Years");
          console.log(text);
          this.yearData.push(text);
          this.currentList.push({
            name: e.currentYear,
            value: e.performanceScore,
            fiscalYear: e.fiscalYear,
          });
        });
        console.log(this.yearData);
        this.drawChart();
      } else {
        this.perList = [];
      }
    },
    drawChart() {
      let myChart = echarts.init(this.$el.querySelector(".per-chart"));
      let option;

      option = {
        color: ["#61BFB4", "#FFD36C"],
        grid: {
          top: 60,
          bottom: 10,
          left: 0,
          right: 0,
          containLabel: true,
        },
        // legend: {
        //   zlevel: 5,
        //   bottom: 0,
        //   itemWidth: 10,
        //   itemHeight: 10,
        //   itemStyle: {
        //     borderCap: "round",
        //     borderRadius: 10,
        //   },
        // },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.yearData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 18,
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 5,
          splitLine: {
            lineStyle: {
              color: "rgba(0, 0, 0, 0.2)",
              width: 1,
              type: "dashed",
            },
          },
        },
        series: [
          {
            name: this.$t("member.naturalyear"),
            data: this.currentList,
            type: "line",
            connectNulls: true,
            Symbol: "circle",
            symbolSize: 18,
            lineStyle: {
              width: 3,
            },
            label: {
              show: true,
              position: "top",
            },
            z: 4,
            itemStyle: {
              borderWidth: 10,
            },
          },
          {
            name: this.$t("member.Fiscalyear"),
            data: this.fiscalList,
            type: "line",
            connectNulls: true,
            symbolSize: 18,
            symbolOffset: [0, 3],
            lineStyle: {
              width: 3,
            },
            label: {
              show: true,
              position: "top",
            },
          },
        ],
      };

      option && myChart.setOption(option);
      $(window).resize(function () {
        console.log(111);
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss">
.chart-wrap {
  width: 100%;
  height: 100%;
  .per-chart {
    width: 100%;
    height: 100%;
  }
}
</style>
