<template>
  <div class="chart-wrap">
    <div class="list-empty" v-show="!perList.length">
      <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
      <p>{{ $t("member.Comingsoon") }}</p>
      <div class="per-empty"></div>
    </div>
    <div class="per-chart" v-show="perList.length"></div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import * as echarts from "echarts";
export default {
  data() {
    return {
      yearData: [],
      perList: [{}],
      upList: [],
      downList: [],
      entryList: [],
    };
  },
  mounted() {
    this.id = this.utils.decryption(this.$route.query.id);
    this.getData();
  },
  methods: {
    ...mapActions({
      getSalary: "moment/getSalary",
    }),
    async getData() {
      let res = await this.getSalary(this.id);
      if (res.data.length) {
        this.perList = [];
        this.perList = [...res.data];
        let upList = JSON.parse(JSON.stringify(this.perList));
        let downList = JSON.parse(JSON.stringify(this.perList));
        upList.forEach((e) => {
          let text = e.currentYear + this.$t("member.Years");
          this.yearData.push(text);
          if (e.thisYearRaise < 0) {
            e.thisYearRaise = null;
          }
          this.upList.push({
            name: e.currentYear,
            value: e.thisYearRaise,
          });
          this.entryList.push({
            name: e.currentYear,
            value: e.entryYearRaise,
          });
        });
        downList.forEach((e) => {
          if (e.thisYearRaise >= 0) {
            e.thisYearRaise = null;
          }
          this.downList.push({
            name: e.currentYear,
            value: e.thisYearRaise,
          });
        });
        this.drawChart();
      } else {
        this.perList = [];
      }
    },
    drawChart() {
      let myChart = echarts.init(this.$el.querySelector(".per-chart"));
      let option;

      option = {
        color: ["#61BFB4", "#FFD36C", "#FFD36C"],
        legend: {
          show: true,
          bottom: 0,
          left: "center",
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 40,
          // itemStyle: {
          //   borderCap: "round",
          //   borderRadius: 10,
          // },
        },
        grid: {
          top: 40,
          bottom: 30,
          left: 30,
          right: 20,
          containLabel: true,
        },
        // tooltip: {
        //   trigger: "axis",
        // },
        xAxis: {
          type: "category",
          data: this.yearData,
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 14,
            // rotate: 30,
            //fontSize: 10,
          },
        },
        yAxis: [
          {
            name: "Yearly Growth",
            nameTextStyle: {
              padding: [0, 0, 0, 30],
            },
            type: "value",
            alignTicks: true,
            axisLabel: {
              formatter: "{value}%",
              color: "rgba(0, 0, 0, 0.3)",
              textStyle: {
                fontSize: 12,
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(0, 0, 0, 0.2)",
                width: 1,
                type: "dashed",
              },
            },
          },
          {
            name: "Cumulative Growth",
            nameTextStyle: {
              padding: [0, 70, 0, 0],
            },
            type: "value",
            alignTicks: true,
            axisLabel: {
              formatter: "{value}%",
              color: "rgba(0, 0, 0, 0.3)",
              textStyle: {
                fontSize: 12,
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(0, 0, 0, 0.2)",
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "Yearly Growth",
            data: this.upList,
            type: "bar",
            barMaxWidth: 35,
            //barMinWidth: 40,
            label: {
              show: true,
              position: "top",
              formatter: "{c}%",
            },
            z: 4,
            itemStyle: {
              borderWidth: 10,
            },
          },
          {
            name: "",
            data: this.downList,
            type: "bar",
            barGap: "-100%",
            barMaxWidth: 35,
            //barMinWidth: 40,
            label: {
              show: true,
              position: "bottom",
              formatter: "{c}%",
            },
          },
          {
            name: "Cumulative Growth",
            data: this.entryList,
            type: "line",
            zlevel: 2,
            yAxisIndex: 1,
            connectNulls: true,
            symbolSize: 12,
            lineStyle: {
              width: 1,
            },
            label: {
              show: true,
              position: "top",
              formatter: "{c}%",
            },
          },
        ],
      };

      option && myChart.setOption(option);
      $(window).resize(function () {
        console.log(111);
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss">
.chart-wrap {
  width: 100%;
  height: 100%;
  .per-chart {
    width: 100%;
    height: 100%;
  }
}
</style>
