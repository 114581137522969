<template>
  <div class="chart-wrap">
    <div v-if="posList.length" class="transfer-block">
      <p class="left-line"></p>
      <div class="tra-list mb32" v-for="(item, i) in posList" :key="i">
        <span class="date"
          ><span class="ch-year">{{ item.changeYear }}</span
          ><br /><span class="ch-date"></span>{{ item.changeDate }}</span
        >
        <p class="point fjc"><span></span></p>

        <p class="title-block fjsba">
          <em>
            <span class="title" :class="{ over: isUat }">{{
              item.jobPosition
            }}</span
            ><br />
            <span class="type">{{ item.changeType }}</span>
          </em>
          <span class="time"
            ><span v-if="i == 0 && lang === 'cn'"
              >{{ $t("member.untilnow") }}
            </span>
            {{ item.stayTimeStr }}
            <span v-if="i == 0 && lang === 'en'">{{
              $t("member.untilnow")
            }}</span></span
          >
        </p>
      </div>
    </div>
    <div class="list-empty" v-else>
      <img class="empty-pic" src="@/src/assets/member/empty.png" alt="" />
      <p>{{ $t("member.Nodata") }}</p>
      <div class="per-empty"></div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      posList: [{}],
      isUat: false,
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.login.lang,
    }),
  },
  mounted() {
    this.id = this.utils.decryption(this.$route.query.id);
    this.getData();
    if (process.env.VUE_APP_MODES === "uat") {
      this.isUat = true;
    } else {
      this.isUat = false;
    }
  },
  methods: {
    ...mapActions({
      getPosition: "moment/getPosition",
    }),
    async getData() {
      let res = await this.getPosition(this.id);
      console.log(res);
      if (res.data.length) {
        this.posList = [];
        this.posList = res.data;
      } else {
        this.posList = [];
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/src/styles/variable.scss";
.chart-wrap {
  width: 100%;
  height: 100%;
  .transfer-block {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: toPad(20);
    overflow-y: auto;
    .left-line {
      position: absolute;
      top: toPad(35);
      left: toPad(82);
      z-index: 1;
      background-color: #c8cfce;
      width: toPad(300);
      height: toPad(1);
      transform: rotate(90deg);
      transform-origin: 0 0;
    }
    .tra-list {
      overflow: hidden;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: toPad(24);
      font-size: toPad(14);
      .point {
        width: toPad(24);
        height: toPad(24);
        margin: 0 toPad(20) 0 toPad(10);
        span {
          position: relative;
          z-index: 1;
          width: toPad(14);
          height: toPad(14);
          border-radius: 50%;
          background: #ffffff;
          border: 4px solid #c8cfce;
        }
      }
      .title-block {
        width: 80%;
        em {
          width: 55%;
          font-style: normal;
          .title {
            width: 100%;
            &.over {
              overflow: hidden;
            }
          }
        }
        .time {
          width: 45%;
          text-align: right;
        }
      }
      span {
        display: inline-block;
        text-align: left;
        &:last-of-type {
          margin-right: 0;
        }

        &.date {
          width: toPad(60);
          color: rgba(0, 0, 0, 0.5);
          text-align: center;
        }
        &.ch-year {
          margin-right: 0;
          font-size: toPad(16);
        }
        &.ch-date {
          font-size: toPad(12);
        }
        &.type {
          height: toPad(22);
          line-height: toPad(22);
          padding: 0 toPad(10);
          background: rgba(208, 208, 208, 0.34);
          border-radius: toPad(34);
        }
      }

      &:nth-of-type(1) {
        font-size: toPad(16);
        font-weight: 600;

        .point {
          span {
            width: toPad(22);
            height: toPad(22);
            border: 5px solid #61bfb4;
          }
        }
        .ch-year {
          font-size: toPad(18);
        }
        .ch-date {
          font-size: toPad(14);
        }
        .type {
          color: #064d33;
          background: rgba(97, 191, 180, 0.19);
          font-size: toPad(14);
          font-weight: normal;
        }
      }
    }
  }
}
</style>
